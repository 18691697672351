import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Context/UserContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../Login/formStyles.css";
import CountryDropdown from "../../Components/CountryDropdown/CountryDropdown";

const Register = () => {
  const [form, setForm] = useState();
  const [errMsg, setErrMsg] = useState();
  const { userData } = useContext(UserContext);
  const history = useHistory();

  // Industry List
  const industries = [
    "Academia",
    "Aerospace/Defense",
    "Agriculture",
    "Automotive",
    "Construction",
    "Consumer Electronics",
    "Energy",
    "Industrial",
    "Information Technology/Computing",
    "Marine/Maritime",
    "Medical",
    "Military",
    "Science",
    "Semiconductor",
    "Technology",
    "Telecommunication",
    "Transportation",
    "Other Industry",
  ];

  // Role List
  const roles = [
    "Consultant",
    "End User",
    "Engineering/Technical",
    "Executive/Manager",
    "Professor/Student",
    "Supply Chain",
    "Other Role",
  ]

  // Show and hide password
  const [showPass, setShowPass] = useState(false);
  const toggleShowPass = () => {
    setShowPass(!showPass);
  };

  const [showCheckPass, setShowCheckPass] = useState(false);
  const toggleShowCheckPass = () => {
    setShowCheckPass(!showCheckPass);
  };

  // Industry Function
  // Store user industry list in array
  const [userInd, setUserInd] = useState([]);
  // Set state when industries are checked and unchecked
  const storeIndustry = (e) => {
    let copyUserInd = [...userInd]
    if (e.target.checked) {
      copyUserInd.push(e.target.name.concat(";"))
    } else if (!e.target.checked) {
      copyUserInd.splice(userInd.indexOf(e.target.name.concat(";")),1)
    }
    setUserInd(copyUserInd);
    // Add Industry to user object
    setForm({
      ...form,
      industry: copyUserInd
    });
  };

  // Role Function
  // Store user role list in array
  const [userRole, setUserRole] = useState([]);
  // Set state when roles are checked and unchecked
  const storeRole = (e) => {
    let copyUserRole = [...userRole]
    if (e.target.checked) {
      copyUserRole.push(e.target.name.concat(";"))
    } else if (!e.target.checked) {
      copyUserRole.splice(userRole.indexOf(e.target.name.concat(";")),1)
    }
    setUserRole(copyUserRole);
    // Add Industry to user object
    setForm({
      ...form,
      role: copyUserRole
    });
  };

  // Country Function
  function handleDataFromDrop(cntry) {
    setForm({
      ...form,
      country: cntry,
    });
  }

  // Survey
  const [other, setOther] = useState(false);
  const surveySelect = (e) => {
    if (e.target.value==="Other") {
      setOther(true)
    } else {
      setOther(false)
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  }

  // Opt-In
  const changeOpt = (e) => {
    if (e.target.checked) {
      setForm({
        ...form,
        optIn: true,
      });
    } else {
      setForm({
        ...form,
        optIn: false,
      });
    }
  }

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/users/register", form);
      history.push("/Confirm");
    } catch (error) {
      setErrMsg(error.response.data.msg);
    }
  };

  useEffect(() => {
    if (userData.user) history.push("/Login");
  }, [userData, history, userInd]);

  return (
    <div>
      <h1 className="form-heading">Register</h1>

      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8 form-box">
          <form onSubmit={onSubmit} className="row g-3 Registration_Form">

            <div className="col-md-6">
              <label
                htmlFor="inputEmail4"
                className="form-label"
                id="email-label"
              >
                Email<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="email"
                className="form-control"
                id="email-input"
                name="email"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="text" className="form-label" id="org-label">
                Organization<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                name="org"
                id="org-input"
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="inputPassword8" className="form-label">
                Password<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type={showPass ? "text" : "password"}
                className="form-control"
                id="password-input"
                placeholder="8 characters minimum"
                name="pass"
                minLength="8"
              />
              <i
                onClick={toggleShowPass}
                className="fa-duotone fa-eye-slash pass-eye"
              ></i>
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword8" className="form-label">
                Re-enter Password<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type={showCheckPass ? "text" : "password"}
                className="form-control"
                id="check-input"
                name="passCheck"
                minLength="8"
                placeholder="8 characters minimum"
              />
              <i
                onClick={toggleShowCheckPass}
                className="fa-duotone fa-eye-slash pass-eye"
              ></i>
            </div>

            <div className="col-md-6">
              <label className="form-label">
                First Name<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                name="first"
                id="first-input"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">
                Last Name<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                name="last"
                id="last-input"
              />
            </div>

            <div className="col-md-6">
              <label className="form-label">
                Industry
              </label>
              <div className="dropdown">
                <a className="form-select border border-secondary reg-list-link" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" href="/#">
                  {userInd.length>0?userInd.map(str => str.replace(';','')).join(", "):"Industry"}
                </a>
                <ul className="dropdown-menu reg-list-menu" aria-labelledby="dropdownMenuLink">
                  {industries.map((industry, idx) => (
                    <li key={idx} className="list-group-item-action">
                      <div className="form-check reg-list-select">
                        <input className="form-check-input list-group-item-primary" type="checkbox" value="" name={industry} onClick={storeIndustry}/>
                        <label>
                          {industry}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <label className="form-label">
                Role
              </label>
              <div className="dropdown">
                <a className="form-select border border-secondary reg-list-link" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" href="/#">
                {userRole.length>0?userRole.map(str => str.replace(';','')).join(", "):"Role"}
                </a>
                <ul className="dropdown-menu reg-list-menu" aria-labelledby="dropdownMenuLink">
                  {roles.map((role, idx) => (
                    <li key={idx} className="list-group-item-action">
                      <div className="form-check reg-list-select">
                        <input className="form-check-input list-group-item-primary" type="checkbox" value="" name={role} onClick={storeRole}/>
                        <label>
                          {role}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="inputCity" className="form-label">
                City<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="city-input"
                name="city"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="inputState" className="form-label">
                State
              </label>
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="state-input"
                name="state"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="inputCountry" className="form-label">
                Country<span className="asterisk">*</span>
              </label>
              <CountryDropdown sendDropdownToReg={handleDataFromDrop}/>
            </div>

            <div className="col-md-6">
              <label htmlFor="inputPhone" className="form-label">
                Phone<span className="asterisk">*</span>
              </label>
              <input
                onChange={onChange}
                type="tel"
                className="form-control"
                id="phone-input"
                name="phone"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputSurvey" className="form-label">
                  How did you hear about us? 
              </label>
              {other?
              <input
                onChange={onChange}
                type="text"
                className="form-control"
                id="survey-input"
                name="survey"
              />:
              <select id="inputSurvey" className="form-select border border-secondary" name="survey" onChange={surveySelect}>
                <option>No Answer</option>
                <option>Search engines</option>
                <option>Internet ads</option>
                <option>Social media</option>
                <option>Referral</option>
                <option>Other</option>
              </select>
              }
            </div>
            
            <div className="col-12">
              <div className="form-check">
                <input
                  onChange={changeOpt}
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  name="optIn"
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  Opt-in to semi-annual mailings
                </label>
              </div>
            </div>
            <div className="col-4">
              <button type="submit" className="btn btn-primary">
                Register
              </button>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-7">
              {errMsg ? (
                <div className="alert alert-danger error-alert" role="alert">
                  {errMsg}
                </div>
              ) : null}
            </div>
          </form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default Register;
